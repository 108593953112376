import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "c61354b5-749d-45b6-930b-b8bfcbe3135a"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "10e537ef-419d-458b-8978-26085927e28a"
export const NavMenuSlugC61354b5749d45b6930bB8bfcbe3135a = "members-primary-nav";

// id: "dc368552-a963-4895-b54e-d9ba9e0d7b15"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "e9cf9d37-aee3-4fde-a27d-1c986c301ceb"
export const NavMenuSlugDc368552A9634895B54eD9ba9e0d7b15 = "members-primary-nav";

// id: "773470ea-1880-4ba9-aebb-37c7dce1b577"
// title: ""
// type: :reference
// key: "about-susan-header"
// parent_id: "e9cf9d37-aee3-4fde-a27d-1c986c301ceb"
export function AboutSusanHeader773470ea18804ba9Aebb37c7dce1b577(props: any) {
  return (
    <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="AboutSusanHeader773470ea18804ba9Aebb37c7dce1b577" {...props} />
  );
}

// id: "ca5f3e2a-8eef-4fda-8f3b-05b16a40c916"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsCa5f3e2a8eef4fda8f3b05b16a40c916(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsCa5f3e2a8eef4fda8f3b05b16a40c916", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-posts2" {...props} />
  );
}

// id: "05bcc94e-02d9-42b2-a6f2-46170c9913fa"
// title: ""
// type: :html
// key: "about-susan-main"
// parent_id: "e9cf9d37-aee3-4fde-a27d-1c986c301ceb"
export function AboutSusanMain05bcc94e02d942b2A6f246170c9913fa(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} AboutSusanMain05bcc94e02d942b2A6f246170c9913fa`}>
      <div className="background-image">
      </div>
      <div className="text-block first">
        <div className="opacity">
        </div>
        <div className="text">
          <h2 className="heading">
            Welcome to the house of Breath.
          </h2>
          <p>
            Here we can slow down, exhale the old, and breathe in the new.
          </p>
          <p>
            Are you looking for deeper ways to connect with yourself?
          </p>
          <p>
            Do you feel desire to experience MORE?
          </p>
          <p>
            More...
          </p>
          <p>
            pleasure, security, magic, wholeness...
          </p>
          <p>
            MORE TURNED ON WITH YOUR LIFE.
          </p>
          <p>
            Answer the beckoning of the heart,
          </p>
          <p>
            I&#39;m here to support you on this journey.
          </p>
        </div>
      </div>
      <div className="page-row color-scheme-1">
        <div className="text-block centered-page-content">
          <div className="text">
            <p className="heading">
              Discover the magic you behold
            </p>
            <p>
              When we get overwhelmed by life and navigate difficult situations, we
              can forget our own magic. Sometimes we are shut down and disconnected
              from our body, our pleasure, our purpose.
            </p>
            <p>
              It&#39;s okay to ask for help, it&#39;s my honor to help remind you. Let&#39;s
              take time to build trust and learn about what you are missing.
            </p>
            <p>
              Sacred space gives us an opportunity to loosen up tension in the body.
              Clear out old chakra patterns. Remember what it feels like to be your
              magical integrated self.
            </p>
            <p>
              House of Breath is a Temple. Serene, sensual, playful.
            </p>
            <div className="button-container">
              <a href="/about/offerings" className="Button transition">
                Explore my Offerings
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="image-block">
      </div>
      <div className="page-row color-scheme-1">
        <div className="text-block centered-page-content testimonials">
          <div className="heading">
            Testimonials
          </div>
          <div className="testimonial">
            <div className="image-container">
              <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/Shout.jpg" alt="photo of CK" />
            </div>
            <div className="text-container">
              <p>
                I felt a warm, tight feeling in my throat as Susan was working on
                opening my throat chakra. I thought of people who I let take my
                voice away and my inner self said &quot;NO MORE, This is my voice, I will
                speak my truth&quot;
              </p>
              <p>
                — CK
              </p>
            </div>
            <div className="bottom" />
          </div>
          <div className="testimonial">
            <div className="image-container">
              <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/Relaxation.jpeg" alt="photo of BB" />
            </div>
            <div className="text-container">
              <p>
                She has a loving intuition that meets you where you are. She helps
                you relax into healing yourself while allowing the possibilities of
                what your life can be to blossom.
              </p>
              <p>
                — BB
              </p>
            </div>
            <div className="bottom" />
          </div>
          <div className="testimonial">
            <div className="image-container">
              <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/Touch.jpg" alt="photo of CK" />
            </div>
            <div className="text-container">
              <p>
                Through her touch, Susan makes a visceral connection that reaches my
                core, sending reverberations throughout my body.
              </p>
              <p>
                — R
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-small">
      </div>
      <div className="contact-container">
        <div className="contact-form block color-scheme-2">
          <div className="heading">
            Reach out for a free consultation!
          </div>
          <p>
            When you feel called to any of my services, it begins with a discovery
            call with SuSun. This is to make sure Tastes of Tantra is a good fit for
            yourself and your relationships!
          </p>
          <div className="button-container">
            <a href="/members/messages/x1RwUD7TogtZzODN" className="Button color2 transition">
              Message SuSun
            </a>
          </div>
        </div>
        <div className="image">
        </div>
      </div>
    </main>
  );
}

// id: "b6a929af-2059-4f4a-be8b-cdcfcf618bc6"
// title: ""
// type: :reference
// key: "about-susan-header"
// parent_id: "e819e58a-34a4-48fd-a248-2a057339ea1f"
export function AboutSusanHeaderB6a929af20594f4aBe8bCdcfcf618bc6(props: any) {
  return (
    <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="AboutSusanHeaderB6a929af20594f4aBe8bCdcfcf618bc6" {...props} />
  );
}

// id: "f8195c72-fbf4-43a0-a5db-a6edd0bd285d"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e819e58a-34a4-48fd-a248-2a057339ea1f"
export function FooterF8195c72Fbf443a0A5dbA6edd0bd285d(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="FooterF8195c72Fbf443a0A5dbA6edd0bd285d" {...props} />
  );
}

// id: "e819e58a-34a4-48fd-a248-2a057339ea1f"
// title: "Offerings"
// type: :html
// key: "about-offerings"
// parent_id: nil
export function AboutOfferingsE819e58a34a448fdA2482a057339ea1f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutOfferingsE819e58a34a448fdA2482a057339ea1f`}>
      {props["about-susan-header"] || <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="AboutSusanHeaderB6a929af20594f4aBe8bCdcfcf618bc6" {...props} />}
      <main>
        <div>
          <div className="gf-cols-2 content-width">
            <div className="image">
              <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/altar.webp" alt="Altar" className="block-image" />
            </div>
            <div className="text-block-vertical">
              <div className="heading">
                What to expect from a session
              </div>
              <ul>
                <li>
                  Reduced stress and anxiety
                </li>
                <li>
                  Increased energetic awareness
                </li>
                <li>
                  Develop more compassion for yourself and others
                </li>
                <li>
                  More capacity to live the life of your dreams
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hero brave">
          <div className="text-block text-block-vertical content-width">
            <div className="heading">
              You&#39;re so brave for showing up!
            </div>
            <p>
              Humans come to me for a variety of different reasons. Healing is a
              very unique journey for everyone. I want to highlight my different
              offerings, yet create space to co-create something perfect for you.
            </p>
          </div>
        </div>
        <div>
          <div className="grid-blocks content-width">
            <div className="image-text-block">
              <div className="image">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-massage.webp" alt="SuSun massage" />
              </div>
              <div className="text">
                <div className="heading">
                  Tantric Awakening
                </div>
                <p>
                  Feel more in your body.
                </p>
                <p>
                  More pleasure. More space. More freedom.
                </p>
                <p>
                  Allow yourself to relax, receive, and enjoy.
                </p>
              </div>
            </div>
            <div className="image-text-block">
              <div className="image">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/pray-art.webp" alt="Chakra" />
              </div>
              <div className="text">
                <div className="heading">
                  Chakra Alignment
                </div>
                <p>
                  Clear out stagnant energy, quiet the noise.
                </p>
                <p>
                  Through Reiki we can prepare your energetic body for a vibrational
                  shift.
                </p>
                <p>
                  Increased intuitive awareness and psychic sensitivity.
                </p>
              </div>
            </div>
            <div className="image-text-block">
              <div className="image">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/sacred-foot.webp" alt="Kinky Tasting" />
              </div>
              <div className="text">
                <div className="heading">
                  Kinky Tasting
                </div>
                <p>
                  Do you have an itch to explore?
                </p>
                <p>
                  Something you have always wanted to try?
                </p>
                <p>
                  Let&#39;s get a taste of those passions you may be a bit shy to talk
                  about.
                </p>
                <p>
                  Through consent, negotiation and boundaries we can explore in new
                  ways.
                </p>
              </div>
            </div>
            <div className="image-text-block">
              <div className="image">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-guiding.webp" alt="SuSun guiding a student" />
              </div>
              <div className="text">
                <div className="heading">
                  Becoming Sex-Positive
                </div>
                <p>
                  Build your communication skills, knowledge and understanding.
                </p>
                <p>
                  Learn your somatic YES, body awareness and the confidence to bring
                  all of yourself into community.
                </p>
                <p>
                  Take a road trip into new and exciting ways of being in
                  relationship.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a href="/members/messages/x1RwUD7TogtZzODN" className="Button">
            Walking with me
          </a>
        </div>
      </main>
      {props["footer"] || <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="FooterF8195c72Fbf443a0A5dbA6edd0bd285d" {...props} />}
    </div>
  );
}

// id: "82dc463b-0451-40fb-9cc9-879f25422880"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "f8720cdf-1c0a-4273-9598-3596d02f8e7f"
export function LinkedLogo82dc463b045140fb9cc9879f25422880(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo82dc463b045140fb9cc9879f25422880", parentTag)} label={<DefaultLogo9a5af62c3570411492b16486c97d07c4 parentTag="LabelBabf26dd0e2f48b8Baf44fc4db955e86" alt="logo" />} {...props} />
  );
}

// id: "206bb41a-30e0-4209-8ffd-71cb1a70320f"
// title: ""
// type: :text
// key: "title"
// parent_id: "405be7b5-5850-479c-97f4-dfef641ad7a7"
export const Title206bb41a30e042098ffd71cb1a70320f = "Default Title";

// id: "af048073-8fde-4261-b3ee-f30cde9853a1"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "17e2894a-182f-4879-ab6d-36f7a28a31fc"
export const NavMenuSlugAf0480738fde4261B3eeF30cde9853a1 = "members-primary-nav";

// id: "3b0b4d74-1733-4ee3-906f-14994fa7e82b"
// title: ""
// type: :text
// key: "title"
// parent_id: "40df26b3-8c66-4ad7-ab11-cf1463ceb15c"
export const Title3b0b4d7417334ee3906f14994fa7e82b = "Secondary Page";

// id: "cf202951-90c4-44db-b3a7-1767ce6971c3"
// title: ""
// type: :text
// key: "title"
// parent_id: "395467a1-a616-4150-9cb2-20bcc3a6bc1a"
export const TitleCf20295190c444dbB3a71767ce6971c3 = "About Susan";

// id: "ca0089de-f284-4a51-96ec-2ebbd002f68a"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePagesCa0089deF2844a5196ec2ebbd002f68a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePagesCa0089deF2844a5196ec2ebbd002f68a`}>
      {props["header"] || <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="HeaderFa2376ec1f2346a4Bfaa8fa911abf3d9" {...props} />}
      {props.children}
    </div>
  );
}

// id: "c5161840-d903-4c0a-a1df-a1833c5e355b"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageC5161840D9034c0aA1dfA1833c5e355b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageC5161840D9034c0aA1dfA1833c5e355b`}>
      {props["header"] || <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="Header77d17bcbF91044a88531B6ba2c3f33a8" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer2832e177F7fc465695210051a914603e" {...props} />}
    </div>
  );
}

// id: "29162e1f-3a6b-4cf9-ae7b-ae97886b4c39"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "f8720cdf-1c0a-4273-9598-3596d02f8e7f"
export const BackgroundImage29162e1f3a6b4cf9Ae7bAe97886b4c39 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "1bfa969f-4dc8-4d3d-9fb5-d07308ba06d7"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "59387023-93cf-4c5f-b160-1d46f059ef2a"
export function Navigation1bfa969f4dc84d3d9fb5D07308ba06d7(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation1bfa969f4dc84d3d9fb5D07308ba06d7", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "a27c01d9-15c5-4d85-acf8-292b5b95533a"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "59df0f69-6b5c-4a67-87cd-864e05f83a6e"
export function HeroA27c01d915c54d85Acf8292b5b95533a(props: any) {
  return (
    <HomePageHero27d08bc010314a188a1cE8813778c76f parentTag="HeroA27c01d915c54d85Acf8292b5b95533a" {...props} />
  );
}

// id: "902b3027-8a7b-4cb1-aba8-9b1cb1117d90"
// title: ""
// type: :html
// key: "logo"
// parent_id: "5a17e842-4610-4f3e-b8f6-813cc358f289"
export function Logo902b30278a7b4cb1Aba89b1cb1117d90(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/logo-wide-white.svg" alt="Demo 1 logo" className={`${parentTag || ""} Logo902b30278a7b4cb1Aba89b1cb1117d90 logo`} />
  );
}

// id: "ad80e0dd-7823-4ad7-93f1-83513d1acf0e"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "d1e0ce1a-5bdd-473d-81ab-03646fde8bd8"
export function HeroAd80e0dd78234ad793f183513d1acf0e(props: any) {
  return (
    <SecondaryMarketingHero405be7b55850479c97f4Dfef641ad7a7 parentTag="HeroAd80e0dd78234ad793f183513d1acf0e" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "c2970e80-0d7b-4bec-88c3-039e5109d061"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "00cafee8-2d84-4f96-a433-b25927ac8f01"
export function SecondaryNavC2970e800d7b4bec88c3039e5109d061(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavC2970e800d7b4bec88c3039e5109d061", parentTag)} {...props} />
  );
}

// id: "8100c1bd-7dd8-4e88-a692-cb67776bb592"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages8100c1bd7dd84e88A692Cb67776bb592(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages8100c1bd7dd84e88A692Cb67776bb592`}>
      {props.children}
    </div>
  );
}

// id: "dddadb01-e947-4811-8342-7ca765ce14f9"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultDddadb01E947481183427ca765ce14f9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultDddadb01E947481183427ca765ce14f9 page`}>
      {props["header"] || <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="Header7c71627c93c74244967d36c08bbae778" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer834f3c1c4948473aB4dc7f29a1247121" {...props} />}
    </div>
  );
}

// id: "9a5af62c-3570-4114-92b1-6486c97d07c4"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo9a5af62c3570411492b16486c97d07c4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/logo-wide-white.svg" className={`${parentTag || ""} DefaultLogo9a5af62c3570411492b16486c97d07c4 logo`} alt="logo" />
  );
}

// id: "71a29b36-dfcb-4947-a442-0fd246a7d2b8"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader71a29b36Dfcb4947A4420fd246a7d2b8(props: any) {
  return (
    <ShortHeaderF8720cdf1c0a427395983596d02f8e7f parentTag="DefaultHeader71a29b36Dfcb4947A4420fd246a7d2b8" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "12538bd3-5316-4cb1-939c-882b3cc23f07"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage12538bd353164cb1939c882b3cc23f07(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage12538bd353164cb1939c882b3cc23f07`}>
      {props["header"] || <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="Header40df26b38c664ad7Ab11Cf1463ceb15c" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="FooterC4a76e036ee94fe0B99eF4f49b3803a5" {...props} />}
    </div>
  );
}

// id: "929b10bc-8651-431f-b044-eec24fa24a46"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote929b10bc8651431fB044Eec24fa24a46(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote929b10bc8651431fB044Eec24fa24a46`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "10e537ef-419d-458b-8978-26085927e28a"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader10e537ef419d458b897826085927e28a(props: any) {
  return (
    <DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a parentTag="MembersAreaHeader10e537ef419d458b897826085927e28a" navMenuSlug="members-primary-nav" header-top={<DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a parentTag="HeaderTop17e2894a182f4879Ab6d36f7a28a31fc" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "ee07daeb-6ba6-432d-96ed-15572e6eb97d"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageEe07daeb6ba6432d96ed15572e6eb97d(props: any) {
  return (
    <SecondaryPage12538bd353164cb1939c882b3cc23f07 parentTag="ResourcesPageEe07daeb6ba6432d96ed15572e6eb97d" header={<SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="HeaderA3d54fed8584477aA9b6F30f5c47c8f2" title="Resources" />} {...props} />
  );
}

// id: "c3675860-bc50-4810-9214-08b098c99e19"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function LabelC3675860Bc504810921408b098c99e19(props: any) {
  return (
    <DefaultLogo9a5af62c3570411492b16486c97d07c4 parentTag="LabelC3675860Bc504810921408b098c99e19" {...props} />
  );
}

// id: "c47f5bb2-028a-49a1-8167-3dee17e55ae6"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageC47f5bb2028a49a181673dee17e55ae6(props: any) {
  return (
    <SecondaryPage12538bd353164cb1939c882b3cc23f07 parentTag="JoinUsPageC47f5bb2028a49a181673dee17e55ae6" header={<SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="Header7b4431de8f8c43d7B1b720a2aa0af4e5" title="Join Us" />} {...props} />
  );
}

// id: "5bb60d48-d088-46a2-8d0e-6f9b187c8027"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage5bb60d48D08846a28d0e6f9b187c8027(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage5bb60d48D08846a28d0e6f9b187c8027 page`}>
      {props["header"] || <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="Header395467a1A61641509cb220bcc3a6bc1a" title="About Susan" {...props} />}
      <main className="main">
        About Susan - TBD
      </main>
      {props["footer"] || <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="FooterD575ad47141d410497061df021991c4c" {...props} />}
    </div>
  );
}

// id: "00cafee8-2d84-4f96-a433-b25927ac8f01"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages00cafee82d844f96A433B25927ac8f01(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages00cafee82d844f96A433B25927ac8f01`}>
      {props["header"] || <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="HeaderF069e57f53f94269B008E22c51717ecf" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavC2970e800d7b4bec88c3039e5109d061", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="FooterEa3ea07e1bd74a3bAa58A9b01983c605" {...props} />}
    </div>
  );
}

// id: "c609d950-6a5c-4f41-9a2f-0f0af08ebf50"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefC609d9506a5c4f419a2f0f0af08ebf50 = "/";

// id: "0c18a5ff-0275-4f81-adb1-5cf9291f192f"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer0c18a5ff02754f81Adb15cf9291f192f(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer0c18a5ff02754f81Adb15cf9291f192f" {...props} />
  );
}

// id: "ac200c0f-78d2-4ddb-ab41-f3b75f15fa75"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesAc200c0f78d24ddbAb41F3b75f15fa75(props: any) {
  return (
    <SecondaryPage12538bd353164cb1939c882b3cc23f07 parentTag="SignupPagesAc200c0f78d24ddbAb41F3b75f15fa75" header={<SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="HeaderCd6ac254Ef634a258f2921ea7f9a58bc" title="Join Us" />} {...props} />
  );
}

// id: "405be7b5-5850-479c-97f4-dfef641ad7a7"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero405be7b55850479c97f4Dfef641ad7a7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero405be7b55850479c97f4Dfef641ad7a7 hero`}>
      <div className="title">
        {props["title"] || Title206bb41a30e042098ffd71cb1a70320f}
      </div>
    </div>
  );
}

// id: "c0e45f4b-c53d-4cc3-b9ab-f99b1831f7bc"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderC0e45f4bC53d4cc3B9abF99b1831f7bc(props: any) {
  return (
    <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="HeaderC0e45f4bC53d4cc3B9abF99b1831f7bc" {...props} />
  );
}

// id: "f8720cdf-1c0a-4273-9598-3596d02f8e7f"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderF8720cdf1c0a427395983596d02f8e7f(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderF8720cdf1c0a427395983596d02f8e7f site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo82dc463b045140fb9cc9879f25422880", parentTag)} label={<DefaultLogo9a5af62c3570411492b16486c97d07c4 parentTag="LabelBabf26dd0e2f48b8Baf44fc4db955e86" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationC5606173E885441393b336497a89a421", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "ef38395e-a293-470c-a1ec-4aab9cf8c774"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageEf38395eA293470cA1ec4aab9cf8c774(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageEf38395eA293470cA1ec4aab9cf8c774`}>
      {props["header"] || <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="HeaderC0f38d9eAd794c64B09a5e772b7d88b8" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer00b9a916452d479cAe9a1ecbb44f84b0" {...props} />}
    </div>
  );
}

// id: "c2ffd247-aec1-46b8-81fc-60e7b6a9bd60"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "ca5f3e2a-8eef-4fda-8f3b-05b16a40c916"
export const ButtonClassC2ffd247Aec146b881fc60e7b6a9bd60 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "17e2894a-182f-4879-ab6d-36f7a28a31fc"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "10e537ef-419d-458b-8978-26085927e28a"
export function HeaderTop17e2894a182f4879Ab6d36f7a28a31fc(props: any) {
  return (
    <DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a parentTag="HeaderTop17e2894a182f4879Ab6d36f7a28a31fc" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "122e3bcf-0bcd-4ac3-a196-345647d43523"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f9497663-6524-4b8d-bf91-a34edc51ebbd"
export function Footer122e3bcf0bcd4ac3A196345647d43523(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer122e3bcf0bcd4ac3A196345647d43523" {...props} />
  );
}

// id: "aa81698e-1f7f-45e7-8246-25d5d431c1cd"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "9a5af62c-3570-4114-92b1-6486c97d07c4"
export const ImageUrlAa81698e1f7f45e7824625d5d431c1cd = "https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/logo-wide-white.svg";

// id: "babf26dd-0e2f-48b8-baf4-4fc4db955e86"
// title: ""
// type: :reference
// key: "label"
// parent_id: "82dc463b-0451-40fb-9cc9-879f25422880"
export function LabelBabf26dd0e2f48b8Baf44fc4db955e86(props: any) {
  return (
    <DefaultLogo9a5af62c3570411492b16486c97d07c4 parentTag="LabelBabf26dd0e2f48b8Baf44fc4db955e86" {...props} />
  );
}

// id: "8d939949-7a97-49ce-bada-de05b531d5d9"
// title: ""
// type: :text
// key: "class"
// parent_id: "1bfa969f-4dc8-4d3d-9fb5-d07308ba06d7"
export const Class8d9399497a9749ceBadaDe05b531d5d9 = "navigation";

// id: "2a9eec11-6f07-4391-8de0-30b6a2ec8c0b"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title2a9eec116f0743918de030b6a2ec8c0b = "Leveling";

// id: "784c7873-8063-426a-bb3a-09f89bdefc88"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "b6f9f19e-517b-435c-80d1-47f8da2ac3dd"
export function MarketingCards784c78738063426aBb3a09f89bdefc88(props: any) {
  return (
    <MarketingCardsCa5f3e2a8eef4fda8f3b05b16a40c916 parentTag="MarketingCards784c78738063426aBb3a09f89bdefc88" postSlug="home-page-posts2" {...props} />
  );
}

// id: "ea3ea07e-1bd7-4a3b-aa58-a9b01983c605"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "00cafee8-2d84-4f96-a433-b25927ac8f01"
export function FooterEa3ea07e1bd74a3bAa58A9b01983c605(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="FooterEa3ea07e1bd74a3bAa58A9b01983c605" {...props} />
  );
}

// id: "ab83b771-57ca-4255-ba88-62058516bc3e"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "59387023-93cf-4c5f-b160-1d46f059ef2a"
export function LinkedLogoAb83b77157ca4255Ba8862058516bc3e(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoAb83b77157ca4255Ba8862058516bc3e", parentTag)} url="/" label={<DefaultLogo9a5af62c3570411492b16486c97d07c4 parentTag="Label4a13456576824bc8Be8eE26c37ccbf57" alt="logo" />} className="linked-logo" {...props} />
  );
}

// id: "cd6ac254-ef63-4a25-8f29-21ea7f9a58bc"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ac200c0f-78d2-4ddb-ab41-f3b75f15fa75"
export function HeaderCd6ac254Ef634a258f2921ea7f9a58bc(props: any) {
  return (
    <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="HeaderCd6ac254Ef634a258f2921ea7f9a58bc" title="Join Us" {...props} />
  );
}

// id: "a3d54fed-8584-477a-a9b6-f30f5c47c8f2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ee07daeb-6ba6-432d-96ed-15572e6eb97d"
export function HeaderA3d54fed8584477aA9b6F30f5c47c8f2(props: any) {
  return (
    <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="HeaderA3d54fed8584477aA9b6F30f5c47c8f2" title="Resources" {...props} />
  );
}

// id: "6195a1c4-b75c-4bcb-8cfb-4f3627d4d76c"
// title: ""
// type: :text
// key: "href"
// parent_id: "7a9ce0d6-ccf3-4ea0-9fca-36d967de021e"
export const Href6195a1c4B75c4bcb8cfb4f3627d4d76c = "/join";

// id: "44d9d10e-2ea1-4005-83cc-87c336e8080d"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "81f69a84-c36b-47d0-86a9-472f97af795b"
export const NavMenuSlug44d9d10e2ea1400583cc87c336e8080d = "footer-nav";

// id: "022e5c70-f61c-4c18-9d42-ed126d079beb"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "59df0f69-6b5c-4a67-87cd-864e05f83a6e"
export const BackgroundImage022e5c70F61c4c189d42Ed126d079beb = "https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/hero4.jpg";

// id: "834f3c1c-4948-473a-b4dc-7f29a1247121"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "dddadb01-e947-4811-8342-7ca765ce14f9"
export function Footer834f3c1c4948473aB4dc7f29a1247121(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer834f3c1c4948473aB4dc7f29a1247121" {...props} />
  );
}

// id: "08ff5563-1edf-4da2-a5a4-ea73348d309d"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "f9497663-6524-4b8d-bf91-a34edc51ebbd"
export function Quote08ff55631edf4da2A5a4Ea73348d309d(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote08ff55631edf4da2A5a4Ea73348d309d", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "c4a76e03-6ee9-4fe0-b99e-f4f49b3803a5"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "12538bd3-5316-4cb1-939c-882b3cc23f07"
export function FooterC4a76e036ee94fe0B99eF4f49b3803a5(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="FooterC4a76e036ee94fe0B99eF4f49b3803a5" {...props} />
  );
}

// id: "00b9a916-452d-479c-ae9a-1ecbb44f84b0"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "ef38395e-a293-470c-a1ec-4aab9cf8c774"
export function Footer00b9a916452d479cAe9a1ecbb44f84b0(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer00b9a916452d479cAe9a1ecbb44f84b0" {...props} />
  );
}

// id: "50235c3f-ccb3-473b-9c44-75a535b8560f"
// title: ""
// type: :text
// key: "url"
// parent_id: "ab83b771-57ca-4255-ba88-62058516bc3e"
export const Url50235c3fCcb3473b9c4475a535b8560f = "/";

// id: "c827d79f-497f-4172-a00d-02eec55b6c70"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "c5606173-e885-4413-93b3-36497a89a421"
export const NavMenuSlugC827d79f497f4172A00d02eec55b6c70 = "members-primary-nav";

// id: "d1e0ce1a-5bdd-473d-81ab-03646fde8bd8"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a parentTag="HeaderTop69bbb13365734cedA681914e2165ec25" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero405be7b55850479c97f4Dfef641ad7a7 parentTag="HeroAd80e0dd78234ad793f183513d1acf0e" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "c5606173-e885-4413-93b3-36497a89a421"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "f8720cdf-1c0a-4273-9598-3596d02f8e7f"
export function NavigationC5606173E885441393b336497a89a421(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationC5606173E885441393b336497a89a421", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "d575ad47-141d-4104-9706-1df021991c4c"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "5bb60d48-d088-46a2-8d0e-6f9b187c8027"
export function FooterD575ad47141d410497061df021991c4c(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="FooterD575ad47141d410497061df021991c4c" {...props} />
  );
}

// id: "91ebca06-1568-49ca-884d-1f2193eb1c35"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "5a17e842-4610-4f3e-b8f6-813cc358f289"
export function Copyright91ebca06156849ca884d1f2193eb1c35(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright91ebca06156849ca884d1f2193eb1c35", parentTag)} {...props} />
  );
}

// id: "2832e177-f7fc-4656-9521-0051a914603e"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c5161840-d903-4c0a-a1df-a1833c5e355b"
export function Footer2832e177F7fc465695210051a914603e(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer2832e177F7fc465695210051a914603e" {...props} />
  );
}

// id: "4938ba16-4f1f-493d-aff0-dde6d9dcaf6a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f9497663-6524-4b8d-bf91-a34edc51ebbd"
export function Header4938ba164f1f493dAff0Dde6d9dcaf6a(props: any) {
  return (
    <HomeHeader59df0f696b5c4a6787cd864e05f83a6e parentTag="Header4938ba164f1f493dAff0Dde6d9dcaf6a" {...props} />
  );
}

// id: "63200a72-bb54-4431-8496-95dc6b041af1"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "08ff5563-1edf-4da2-a5a4-ea73348d309d"
export const ContentSlug63200a72Bb544431849695dc6b041af1 = "home-page-quote";

// id: "9a0c9cf9-b085-4383-8b4d-d448a80c7b48"
// title: ""
// type: :text
// key: "title"
// parent_id: "ad80e0dd-7823-4ad7-93f1-83513d1acf0e"
export const Title9a0c9cf9B08543838b4dD448a80c7b48 = "Secondary Marketing Title 2";

// id: "fd60ed12-8891-4836-b634-2892cd8a2a7c"
// title: ""
// type: :text
// key: "title"
// parent_id: "a3d54fed-8584-477a-a9b6-f30f5c47c8f2"
export const TitleFd60ed1288914836B6342892cd8a2a7c = "Resources";

// id: "81f69a84-c36b-47d0-86a9-472f97af795b"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "5a17e842-4610-4f3e-b8f6-813cc358f289"
export function Links81f69a84C36b47d086a9472f97af795b(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links81f69a84C36b47d086a9472f97af795b", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "fa2376ec-1f23-46a4-bfaa-8fa911abf3d9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ca0089de-f284-4a51-96ec-2ebbd002f68a"
export function HeaderFa2376ec1f2346a4Bfaa8fa911abf3d9(props: any) {
  return (
    <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="HeaderFa2376ec1f2346a4Bfaa8fa911abf3d9" {...props} />
  );
}

// id: "7a9ce0d6-ccf3-4ea0-9fca-36d967de021e"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "27d08bc0-1031-4a18-8a1c-e8813778c76f"
export function Button7a9ce0d6Ccf34ea09fca36d967de021e(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button7a9ce0d6Ccf34ea09fca36d967de021e", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "108c2011-a198-462f-8d0c-3f913b078ccc"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "59df0f69-6b5c-4a67-87cd-864e05f83a6e"
export function HeaderTop108c2011A198462f8d0c3f913b078ccc(props: any) {
  return (
    <DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a parentTag="HeaderTop108c2011A198462f8d0c3f913b078ccc" {...props} />
  );
}

// id: "0a54cd6a-d6da-4063-97f7-d8329b0afde4"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "ca5f3e2a-8eef-4fda-8f3b-05b16a40c916"
export const PostSlug0a54cd6aD6da406397f7D8329b0afde4 = "home-page-posts2";

// id: "4a134565-7682-4bc8-be8e-e26c37ccbf57"
// title: ""
// type: :reference
// key: "label"
// parent_id: "ab83b771-57ca-4255-ba88-62058516bc3e"
export function Label4a13456576824bc8Be8eE26c37ccbf57(props: any) {
  return (
    <DefaultLogo9a5af62c3570411492b16486c97d07c4 parentTag="Label4a13456576824bc8Be8eE26c37ccbf57" {...props} />
  );
}

// id: "16bd0ed7-6172-4f57-a4f9-95ad52014c8a"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "1bfa969f-4dc8-4d3d-9fb5-d07308ba06d7"
export const NavMenuSlug16bd0ed761724f57A4f995ad52014c8a = "marketing-primary-nav";

// id: "80155142-35e4-4920-be01-e49c2de76bcd"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "f9497663-6524-4b8d-bf91-a34edc51ebbd"
export function WelcomeBack8015514235e44920Be01E49c2de76bcd(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack8015514235e44920Be01E49c2de76bcd", parentTag)} {...props} />
  );
}

// id: "56c1b072-3e66-49c8-9095-087f1595c990"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "71a29b36-dfcb-4947-a442-0fd246a7d2b8"
export const NavMenuSlug56c1b0723e6649c89095087f1595c990 = "marketing-primary-nav";

// id: "69bbb133-6573-4ced-a681-914e2165ec25"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "d1e0ce1a-5bdd-473d-81ab-03646fde8bd8"
export function HeaderTop69bbb13365734cedA681914e2165ec25(props: any) {
  return (
    <DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a parentTag="HeaderTop69bbb13365734cedA681914e2165ec25" {...props} />
  );
}

// id: "f069e57f-53f9-4269-b008-e22c51717ecf"
// title: ""
// type: :reference
// key: "header"
// parent_id: "00cafee8-2d84-4f96-a433-b25927ac8f01"
export function HeaderF069e57f53f94269B008E22c51717ecf(props: any) {
  return (
    <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="HeaderF069e57f53f94269B008E22c51717ecf" {...props} />
  );
}

// id: "c0f38d9e-ad79-4c64-b09a-5e772b7d88b8"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ef38395e-a293-470c-a1ec-4aab9cf8c774"
export function HeaderC0f38d9eAd794c64B09a5e772b7d88b8(props: any) {
  return (
    <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="HeaderC0f38d9eAd794c64B09a5e772b7d88b8" title="Leveling" {...props} />
  );
}

// id: "1629423f-cab8-4bfe-842c-8a36de5839d2"
// title: ""
// type: :text
// key: "class"
// parent_id: "ab83b771-57ca-4255-ba88-62058516bc3e"
export const Class1629423fCab84bfe842c8a36de5839d2 = "linked-logo";

// id: "651cf8dc-8759-4761-82bf-86239d793ef4"
// title: ""
// type: :text
// key: "title"
// parent_id: "c0f38d9e-ad79-4c64-b09a-5e772b7d88b8"
export const Title651cf8dc8759476182bf86239d793ef4 = "Leveling";

// id: "eb56987a-4ccf-43f9-8607-fa4fa68b785b"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "5a17e842-4610-4f3e-b8f6-813cc358f289"
export function GroupflowEb56987a4ccf43f98607Fa4fa68b785b(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowEb56987a4ccf43f98607Fa4fa68b785b", parentTag)} {...props} />
  );
}

// id: "395467a1-a616-4150-9cb2-20bcc3a6bc1a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "5bb60d48-d088-46a2-8d0e-6f9b187c8027"
export function Header395467a1A61641509cb220bcc3a6bc1a(props: any) {
  return (
    <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="Header395467a1A61641509cb220bcc3a6bc1a" title="About Susan" {...props} />
  );
}

// id: "7c71627c-93c7-4244-967d-36c08bbae778"
// title: ""
// type: :reference
// key: "header"
// parent_id: "dddadb01-e947-4811-8342-7ca765ce14f9"
export function Header7c71627c93c74244967d36c08bbae778(props: any) {
  return (
    <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="Header7c71627c93c74244967d36c08bbae778" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "ef4d46a6-d94b-404a-91b4-aee70bb6954c"
// title: ""
// type: :reference
// key: "body"
// parent_id: "f9497663-6524-4b8d-bf91-a34edc51ebbd"
export function BodyEf4d46a6D94b404a91b4Aee70bb6954c(props: any) {
  return (
    <MarketingBodyB6f9f19e517b435c80d147f8da2ac3dd parentTag="BodyEf4d46a6D94b404a91b4Aee70bb6954c" {...props} />
  );
}

// id: "d6661523-bbac-4fd0-9b69-d3e04e8b7945"
// title: ""
// type: :text
// key: "title"
// parent_id: "71a29b36-dfcb-4947-a442-0fd246a7d2b8"
export const TitleD6661523Bbac4fd09b69D3e04e8b7945 = null;

// id: "00baba2d-5c20-45dd-b7b4-d07729e011b7"
// title: ""
// type: :text
// key: "title"
// parent_id: "7b4431de-8f8c-43d7-b1b7-20a2aa0af4e5"
export const Title00baba2d5c2045ddB7b4D07729e011b7 = "Join Us";

// id: "8b78afed-240b-47dc-ae26-265fbcefbd65"
// title: ""
// type: :text
// key: "title"
// parent_id: "77d17bcb-f910-44a8-8531-b6ba2c3f33a8"
export const Title8b78afed240b47dcAe26265fbcefbd65 = "Public Events";

// id: "ff6ad790-f3c0-40fd-b21f-d350ed108195"
// title: ""
// type: :reference
// key: "body"
// parent_id: "5bb60d48-d088-46a2-8d0e-6f9b187c8027"
export function BodyFf6ad790F3c040fdB21fD350ed108195(props: any) {
  return (
    <MarketingBodyB6f9f19e517b435c80d147f8da2ac3dd parentTag="BodyFf6ad790F3c040fdB21fD350ed108195" postSlug="about-us-posts" {...props} />
  );
}

// id: "77d17bcb-f910-44a8-8531-b6ba2c3f33a8"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c5161840-d903-4c0a-a1df-a1833c5e355b"
export function Header77d17bcbF91044a88531B6ba2c3f33a8(props: any) {
  return (
    <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="Header77d17bcbF91044a88531B6ba2c3f33a8" title="Public Events" {...props} />
  );
}

// id: "e1b4d510-26f3-498f-bf50-0c71c3d5cf70"
// title: ""
// type: :text
// key: "alt"
// parent_id: "9a5af62c-3570-4114-92b1-6486c97d07c4"
export const AltE1b4d51026f3498fBf500c71c3d5cf70 = "logo";

// id: "236cd7cc-5d8f-41d5-a7d7-50e270eb0440"
// title: ""
// type: :text
// key: "label"
// parent_id: "7a9ce0d6-ccf3-4ea0-9fca-36d967de021e"
export const Label236cd7cc5d8f41d5A7d750e270eb0440 = "Join Us";

// id: "c2b0cc3c-2340-4a88-977e-992519c4ea43"
// title: ""
// type: :text
// key: "title"
// parent_id: "cd6ac254-ef63-4a25-8f29-21ea7f9a58bc"
export const TitleC2b0cc3c23404a88977e992519c4ea43 = "Join Us";

// id: "9298f4a3-2ac9-48cb-9a4a-c956cb536851"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "784c7873-8063-426a-bb3a-09f89bdefc88"
export const PostSlug9298f4a32ac948cb9a4aC956cb536851 = "home-page-posts2";

// id: "7b4431de-8f8c-43d7-b1b7-20a2aa0af4e5"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c47f5bb2-028a-49a1-8167-3dee17e55ae6"
export function Header7b4431de8f8c43d7B1b720a2aa0af4e5(props: any) {
  return (
    <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="Header7b4431de8f8c43d7B1b720a2aa0af4e5" title="Join Us" {...props} />
  );
}

// id: "40df26b3-8c66-4ad7-ab11-cf1463ceb15c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "12538bd3-5316-4cb1-939c-882b3cc23f07"
export function Header40df26b38c664ad7Ab11Cf1463ceb15c(props: any) {
  return (
    <SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8 parentTag="Header40df26b38c664ad7Ab11Cf1463ceb15c" title="Secondary Page" {...props} />
  );
}

// id: "591498c9-6568-43eb-9a21-2db82420c1cf"
// title: ""
// type: :text
// key: "title"
// parent_id: "d1e0ce1a-5bdd-473d-81ab-03646fde8bd8"
export const Title591498c9656843eb9a212db82420c1cf = "Secondary Marketing Title 1";

// id: "9e2d6871-60c4-4426-b879-dd00e29a0ffb"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "7c71627c-93c7-4244-967d-36c08bbae778"
export const NavMenuSlug9e2d687160c44426B879Dd00e29a0ffb = "members-primary-nav";

// id: "12053638-b779-4726-b2e3-5986e66c1cbd"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "ff6ad790-f3c0-40fd-b21f-d350ed108195"
export const PostSlug12053638B7794726B2e35986e66c1cbd = "about-us-posts";

// id: "b6f9f19e-517b-435c-80d1-47f8da2ac3dd"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyB6f9f19e517b435c80d147f8da2ac3dd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyB6f9f19e517b435c80d147f8da2ac3dd page-body`}>
      {props["marketingCards"] || <MarketingCardsCa5f3e2a8eef4fda8f3b05b16a40c916 parentTag="MarketingCards784c78738063426aBb3a09f89bdefc88" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "f9497663-6524-4b8d-bf91-a34edc51ebbd"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageF949766365244b8dBf91A34edc51ebbd(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageF949766365244b8dBf91A34edc51ebbd page`}>
      {props["header"] || <HomeHeader59df0f696b5c4a6787cd864e05f83a6e parentTag="Header4938ba164f1f493dAff0Dde6d9dcaf6a" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack8015514235e44920Be01E49c2de76bcd", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyB6f9f19e517b435c80d147f8da2ac3dd parentTag="BodyEf4d46a6D94b404a91b4Aee70bb6954c" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote08ff55631edf4da2A5a4Ea73348d309d", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer122e3bcf0bcd4ac3A196345647d43523" {...props} />}
    </div>
  );
}

// id: "59df0f69-6b5c-4a67-87cd-864e05f83a6e"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader59df0f696b5c4a6787cd864e05f83a6e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader59df0f696b5c4a6787cd864e05f83a6e site-header`}>
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a parentTag="HeaderTop108c2011A198462f8d0c3f913b078ccc" {...props} />}
      </div>
      <div className="relative hero-wrapper">
        <div className="background-image" />
        {props["hero"] || <HomePageHero27d08bc010314a188a1cE8813778c76f parentTag="HeroA27c01d915c54d85Acf8292b5b95533a" {...props} />}
      </div>
    </div>
  );
}

// id: "e9cf9d37-aee3-4fde-a27d-1c986c301ceb"
// title: "About SuSun"
// type: :html
// key: "about-susan"
// parent_id: nil
export function AboutSusanE9cf9d37Aee34fdeA27d1c986c301ceb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutSusanE9cf9d37Aee34fdeA27d1c986c301ceb`} navMenuSlug="members-primary-nav">
      {props["about-susan-header"] || <MembersAreaHeader10e537ef419d458b897826085927e28a parentTag="AboutSusanHeader773470ea18804ba9Aebb37c7dce1b577" {...props} />}
      {props["about-susan-main"] || <AboutSusanMain05bcc94e02d942b2A6f246170c9913fa />}
      {props["footer"] || <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer9ca47e9911e341aeA7bf0bc7f075fd90" {...props} />}
    </div>
  );
}

// id: "59387023-93cf-4c5f-b160-1d46f059ef2a"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoAb83b77157ca4255Ba8862058516bc3e", parentTag)} url="/" label={<DefaultLogo9a5af62c3570411492b16486c97d07c4 parentTag="Label4a13456576824bc8Be8eE26c37ccbf57" alt="logo" />} className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation1bfa969f4dc84d3d9fb5D07308ba06d7", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "0735da97-51da-43ca-9cbf-e864491f1f4f"
// title: "Header Top"
// type: :reference
// key: "header-top"
// parent_id: "e9cf9d37-aee3-4fde-a27d-1c986c301ceb"
export function HeaderTop0735da9751da43ca9cbfE864491f1f4f(props: any) {
  return (
    <DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a parentTag="HeaderTop0735da9751da43ca9cbfE864491f1f4f" {...props} />
  );
}

// id: "9ca47e99-11e3-41ae-a7bf-0bc7f075fd90"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e9cf9d37-aee3-4fde-a27d-1c986c301ceb"
export function Footer9ca47e9911e341aeA7bf0bc7f075fd90(props: any) {
  return (
    <DefaultFooter5a17e84246104f3eB8f6813cc358f289 parentTag="Footer9ca47e9911e341aeA7bf0bc7f075fd90" {...props} />
  );
}

// id: "27d08bc0-1031-4a18-8a1c-e8813778c76f"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero27d08bc010314a188a1cE8813778c76f(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero27d08bc010314a188a1cE8813778c76f hero`}>
      <div className="title">
        Remember you are the Magic
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button7a9ce0d6Ccf34ea09fca36d967de021e", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "5a17e842-4610-4f3e-b8f6-813cc358f289"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter5a17e84246104f3eB8f6813cc358f289(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter5a17e84246104f3eB8f6813cc358f289 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links81f69a84C36b47d086a9472f97af795b", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo902b30278a7b4cb1Aba89b1cb1117d90 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright91ebca06156849ca884d1f2193eb1c35", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowEb56987a4ccf43f98607Fa4fa68b785b", parentTag)} {...props} />}
    </div>
  );
}

// id: "5e1af9af-ca39-45df-94ed-f87a2ddf25fa"
// title: ""
// type: :text
// key: "class"
// parent_id: "ccd4aefe-6df9-4b2c-9b62-5e5fee420fea"
export const Class5e1af9afCa3945df94edF87a2ddf25fa = "navigation";

// id: "ccd4aefe-6df9-4b2c-9b62-5e5fee420fea"
// title: "Members Area Navigation"
// type: :gf_preset
// key: "navigation"
// parent_id: "f069e57f-53f9-4269-b008-e22c51717ecf"
export function NavigationCcd4aefe6df94b2c9b625e5fee420fea(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationCcd4aefe6df94b2c9b625e5fee420fea", parentTag)} className="navigation" {...props} />
  );
}

const Components = {
  NavMenuSlugC61354b5749d45b6930bB8bfcbe3135a,
  NavMenuSlugDc368552A9634895B54eD9ba9e0d7b15,
  AboutSusanHeader773470ea18804ba9Aebb37c7dce1b577,
  MarketingCardsCa5f3e2a8eef4fda8f3b05b16a40c916,
  AboutSusanMain05bcc94e02d942b2A6f246170c9913fa,
  AboutSusanHeaderB6a929af20594f4aBe8bCdcfcf618bc6,
  FooterF8195c72Fbf443a0A5dbA6edd0bd285d,
  AboutOfferingsE819e58a34a448fdA2482a057339ea1f,
  LinkedLogo82dc463b045140fb9cc9879f25422880,
  Title206bb41a30e042098ffd71cb1a70320f,
  NavMenuSlugAf0480738fde4261B3eeF30cde9853a1,
  Title3b0b4d7417334ee3906f14994fa7e82b,
  TitleCf20295190c444dbB3a71767ce6971c3,
  MessagePagesCa0089deF2844a5196ec2ebbd002f68a,
  PublicEventsPageC5161840D9034c0aA1dfA1833c5e355b,
  BackgroundImage29162e1f3a6b4cf9Ae7bAe97886b4c39,
  Navigation1bfa969f4dc84d3d9fb5D07308ba06d7,
  HeroA27c01d915c54d85Acf8292b5b95533a,
  Logo902b30278a7b4cb1Aba89b1cb1117d90,
  HeroAd80e0dd78234ad793f183513d1acf0e,
  SecondaryNavC2970e800d7b4bec88c3039e5109d061,
  AdminPages8100c1bd7dd84e88A692Cb67776bb592,
  MembersAreaDefaultDddadb01E947481183427ca765ce14f9,
  DefaultLogo9a5af62c3570411492b16486c97d07c4,
  DefaultHeader71a29b36Dfcb4947A4420fd246a7d2b8,
  SecondaryPage12538bd353164cb1939c882b3cc23f07,
  PageQuote929b10bc8651431fB044Eec24fa24a46,
  MembersAreaHeader10e537ef419d458b897826085927e28a,
  ResourcesPageEe07daeb6ba6432d96ed15572e6eb97d,
  LabelC3675860Bc504810921408b098c99e19,
  JoinUsPageC47f5bb2028a49a181673dee17e55ae6,
  AboutUsPage5bb60d48D08846a28d0e6f9b187c8027,
  MemberPages00cafee82d844f96A433B25927ac8f01,
  HrefC609d9506a5c4f419a2f0f0af08ebf50,
  Footer0c18a5ff02754f81Adb15cf9291f192f,
  SignupPagesAc200c0f78d24ddbAb41F3b75f15fa75,
  SecondaryMarketingHero405be7b55850479c97f4Dfef641ad7a7,
  HeaderC0e45f4bC53d4cc3B9abF99b1831f7bc,
  ShortHeaderF8720cdf1c0a427395983596d02f8e7f,
  AboutLevelingPageEf38395eA293470cA1ec4aab9cf8c774,
  ButtonClassC2ffd247Aec146b881fc60e7b6a9bd60,
  HeaderTop17e2894a182f4879Ab6d36f7a28a31fc,
  Footer122e3bcf0bcd4ac3A196345647d43523,
  ImageUrlAa81698e1f7f45e7824625d5d431c1cd,
  LabelBabf26dd0e2f48b8Baf44fc4db955e86,
  Class8d9399497a9749ceBadaDe05b531d5d9,
  Title2a9eec116f0743918de030b6a2ec8c0b,
  MarketingCards784c78738063426aBb3a09f89bdefc88,
  FooterEa3ea07e1bd74a3bAa58A9b01983c605,
  LinkedLogoAb83b77157ca4255Ba8862058516bc3e,
  HeaderCd6ac254Ef634a258f2921ea7f9a58bc,
  HeaderA3d54fed8584477aA9b6F30f5c47c8f2,
  Href6195a1c4B75c4bcb8cfb4f3627d4d76c,
  NavMenuSlug44d9d10e2ea1400583cc87c336e8080d,
  BackgroundImage022e5c70F61c4c189d42Ed126d079beb,
  Footer834f3c1c4948473aB4dc7f29a1247121,
  Quote08ff55631edf4da2A5a4Ea73348d309d,
  FooterC4a76e036ee94fe0B99eF4f49b3803a5,
  Footer00b9a916452d479cAe9a1ecbb44f84b0,
  Url50235c3fCcb3473b9c4475a535b8560f,
  NavMenuSlugC827d79f497f4172A00d02eec55b6c70,
  SecondaryMarketingHeaderD1e0ce1a5bdd473d81ab03646fde8bd8,
  NavigationC5606173E885441393b336497a89a421,
  FooterD575ad47141d410497061df021991c4c,
  Copyright91ebca06156849ca884d1f2193eb1c35,
  Footer2832e177F7fc465695210051a914603e,
  Header4938ba164f1f493dAff0Dde6d9dcaf6a,
  ContentSlug63200a72Bb544431849695dc6b041af1,
  Title9a0c9cf9B08543838b4dD448a80c7b48,
  TitleFd60ed1288914836B6342892cd8a2a7c,
  Links81f69a84C36b47d086a9472f97af795b,
  HeaderFa2376ec1f2346a4Bfaa8fa911abf3d9,
  Button7a9ce0d6Ccf34ea09fca36d967de021e,
  HeaderTop108c2011A198462f8d0c3f913b078ccc,
  PostSlug0a54cd6aD6da406397f7D8329b0afde4,
  Label4a13456576824bc8Be8eE26c37ccbf57,
  NavMenuSlug16bd0ed761724f57A4f995ad52014c8a,
  WelcomeBack8015514235e44920Be01E49c2de76bcd,
  NavMenuSlug56c1b0723e6649c89095087f1595c990,
  HeaderTop69bbb13365734cedA681914e2165ec25,
  HeaderF069e57f53f94269B008E22c51717ecf,
  HeaderC0f38d9eAd794c64B09a5e772b7d88b8,
  Class1629423fCab84bfe842c8a36de5839d2,
  Title651cf8dc8759476182bf86239d793ef4,
  GroupflowEb56987a4ccf43f98607Fa4fa68b785b,
  Header395467a1A61641509cb220bcc3a6bc1a,
  Header7c71627c93c74244967d36c08bbae778,
  BodyEf4d46a6D94b404a91b4Aee70bb6954c,
  TitleD6661523Bbac4fd09b69D3e04e8b7945,
  Title00baba2d5c2045ddB7b4D07729e011b7,
  Title8b78afed240b47dcAe26265fbcefbd65,
  BodyFf6ad790F3c040fdB21fD350ed108195,
  Header77d17bcbF91044a88531B6ba2c3f33a8,
  AltE1b4d51026f3498fBf500c71c3d5cf70,
  Label236cd7cc5d8f41d5A7d750e270eb0440,
  TitleC2b0cc3c23404a88977e992519c4ea43,
  PostSlug9298f4a32ac948cb9a4aC956cb536851,
  Header7b4431de8f8c43d7B1b720a2aa0af4e5,
  Header40df26b38c664ad7Ab11Cf1463ceb15c,
  Title591498c9656843eb9a212db82420c1cf,
  NavMenuSlug9e2d687160c44426B879Dd00e29a0ffb,
  PostSlug12053638B7794726B2e35986e66c1cbd,
  MarketingBodyB6f9f19e517b435c80d147f8da2ac3dd,
  HomePageF949766365244b8dBf91A34edc51ebbd,
  HomeHeader59df0f696b5c4a6787cd864e05f83a6e,
  AboutSusanE9cf9d37Aee34fdeA27d1c986c301ceb,
  DefaultHeaderTop5938702393cf4c5fB1601d46f059ef2a,
  HeaderTop0735da9751da43ca9cbfE864491f1f4f,
  Footer9ca47e9911e341aeA7bf0bc7f075fd90,
  HomePageHero27d08bc010314a188a1cE8813778c76f,
  DefaultFooter5a17e84246104f3eB8f6813cc358f289,
  Class5e1af9afCa3945df94edF87a2ddf25fa,
  NavigationCcd4aefe6df94b2c9b625e5fee420fea
}

export default Components;