const ROUTES = {
  "/": {
    "name": "HomePageF949766365244b8dBf91A34edc51ebbd",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages8100c1bd7dd84e88A692Cb67776bb592",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage5bb60d48D08846a28d0e6f9b187c8027",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageEf38395eA293470cA1ec4aab9cf8c774",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/about/offerings": {
    "name": "AboutOfferingsE819e58a34a448fdA2482a057339ea1f",
    "type": "HTML",
    "key": "about-offerings"
  },
  "/about/susan": {
    "name": "AboutSusanE9cf9d37Aee34fdeA27d1c986c301ceb",
    "type": "HTML",
    "key": "about-susan"
  },
  "/events/public": {
    "name": "PublicEventsPageC5161840D9034c0aA1dfA1833c5e355b",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageC47f5bb2028a49a181673dee17e55ae6",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages00cafee82d844f96A433B25927ac8f01",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePagesCa0089deF2844a5196ec2ebbd002f68a",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPageEe07daeb6ba6432d96ed15572e6eb97d",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesAc200c0f78d24ddbAb41F3b75f15fa75",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;